﻿const isTouchDevice = () =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

const MyEvent = {
    HIDDEN: "hidden.generic.modal",
    SHOWN: "shown.generic.modal",
    SHOW: "show.generic.modal",
    HIDE: "hide.generic.modal",
};

const MyModals = [];

class Modal {
    constructor(element) {
        this._element = element;
        this._dialog = element.querySelector(".modal-dialog");
        this._backdrop = null;
        this._isShown = false;
        this._dismissModalHandler = this._dismissModalHandler.bind(this);
        this._backdropDismissHandler = this._backdropDismissHandler.bind(this);
        this._closeOnEscHandler = this._closeOnEscHandler.bind(this);
    }

    _getScrollbarSize() {
        if (this.scrollbarSize === undefined) {
            const scrollDiv = document.createElement("div");
            scrollDiv.style.cssText = `
                width: 99px;
                height: 99px;
                overflow: scroll;
                position: absolute;
                top: -9999px;
            `;
            document.body.appendChild(scrollDiv);
            this.scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
        }
        return this.scrollbarSize;
    }

    show(relatedTarget) {
        if (this._isShown) {
            return;
        }

        this._element.dispatchEvent(new CustomEvent(MyEvent.SHOW, { detail: { relatedTarget: relatedTarget } }));

        this._isShown = true;
        document.documentElement.classList.add("modal-open");

        if (!isTouchDevice()) {
            document.querySelector("html").style.marginRight =
                this._getScrollbarSize() + "px";
        }

        this._bindHandlers();
        this._showBackdrop();
        this._showElement();

        this._element.dispatchEvent(new CustomEvent(MyEvent.SHOWN, { detail: { relatedTarget: relatedTarget } }));
    }

    hide(event) {
        if (event) {
            event.preventDefault();
        }

        if (!this._isShown) {
            return;
        }

        this._element.dispatchEvent(new CustomEvent(MyEvent.HIDE));

        this._isShown = false;

        this._unbindHandlers();

        this._element.classList.remove("show");

        this._element.style.display = "none";
        this._element.setAttribute("aria-hidden", true);
        this._element.removeAttribute("aria-modal");

        if (!this._isShown && this._backdrop) {
            this._backdrop.classList.remove("show");
            this._removeBackdrop();
            document.documentElement.classList.remove("modal-open");
        }

        this._element.dispatchEvent(new CustomEvent(MyEvent.HIDDEN));
        document.querySelector("html").style.removeProperty("margin-right");
    }

    _bindHandlers() {
        if (this._element.querySelector('[data-dismiss="modal"]')) {
            this._element
                .querySelectorAll('[data-dismiss="modal"]')
                .forEach((item) => {
                    item.addEventListener("click", this._dismissModalHandler);
                });
        }

        this._element.addEventListener("click", this._backdropDismissHandler);
        this._element.addEventListener("keydown", this._closeOnEscHandler);
    }

    _unbindHandlers() {
        if (this._element.querySelector('[data-dismiss="modal"]')) {
            this._element
                .querySelectorAll('[data-dismiss="modal"]')
                .forEach((item) => {
                    item.removeEventListener("click", this._dismissModalHandler);
                });
        }

        this._element.removeEventListener("click", this._backdropDismissHandler);
        this._element.removeEventListener("keydown", this._closeOnEscHandler);
    }

    _dismissModalHandler(e) {
        this.hide(e);
    }

    _backdropDismissHandler(e) {
        if (e.target !== e.currentTarget) {
            return;
        }
        this.hide(e);
    }

    _closeOnEscHandler(e) {
        if (e.code === "Escape") {
            this.hide(e);
        }
    }

    _showElement() {
        this._element.style.display = "block";
        this._element.removeAttribute("aria-hidden");
        this._element.setAttribute("aria-modal", true);
        this._element.scrollTop = 0;
        this._element.classList.add("show");
        this._element.focus();
    }

    _removeBackdrop() {
        const existingBackdrops = document.querySelectorAll('.modal-backdrop');
        existingBackdrops.forEach(backdrop => {
            backdrop.parentNode.removeChild(backdrop);
        });
        this._backdrop = null;
    }

    _showBackdrop() {
        if (!document.querySelector(".modal-backdrop")) {
            this._backdrop = document.createElement("div");
            this._backdrop.className = "modal-backdrop show";
            document.body.prepend(this._backdrop);
            this._element.addEventListener("click", this._backdropDismissHandler);
        }
    }
}

Modal.hide = (modalElement) => {
    const modalObject = MyModals.find((modal) => modal._element === modalElement);

    if (modalObject) {
        modalObject.hide();
    }
};

Modal.show = (modalElement, relatedTarget) => {
    if (!modalElement) {
        return;
    }
    const modalObject = MyModals.find((modal) => modal._element === modalElement);
    if (!modalObject) {
        const modal = new Modal(modalElement);
        MyModals.push(modal);
        modal.show(relatedTarget);
    } else {
        modalObject.show(relatedTarget);
    }
};

Modal.init = (modalElement) => {
    if (!modalElement) {
        return;
    }
    const modalObject = MyModals.find((modal) => modal._element === modalElement);
    if (!modalObject) {
        const modal = new Modal(modalElement);
        MyModals.push(modal);
    }
};

document.addEventListener("click", (event) => {
    const target = event.target.closest('[data-toggle="modal"]');
    if (target) {
        const modalSelector = target.getAttribute("data-target");
        const modalElement = document.querySelector(modalSelector);

        const modalObject = MyModals.find((modal) => modal._element === modalElement);
        if (!modalObject) {
            Modal.init(modalElement);
        }

        if (!modalElement.classList.contains("show")) {
            Modal.show(modalElement, target);
        }
    }
});

export default Modal;
